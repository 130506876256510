import styles from "./Questions.module.css";
import { questions } from "../../constants/questions";
import Accordion from "../utils/Accordion";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Questions = () => {
  return (
    <div className={`${styles.QuestionWrapper} center`}>
      <div className={`${styles.QuestionsWrapperInner} center`}>
        <AnimationOnScroll
          animateIn="animate__zoomIn"
          className={styles.QuestionsHeading}
        >
          <p>FAQ</p>
        </AnimationOnScroll>
        <div className={`${styles.QuestionsList} center`}>
          {questions.map(({ question, answer }) => {
            return (
              <Accordion
                isUpper={true}
                title={question}
                content={answer}
                iconOpen="plus"
                iconClose="minus"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Questions;
