import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <nav className={`${styles.navbarWrapper} center`}>
      <div className={`${styles.navbarColumn} center`}>
        <div className={`${styles.navbarInner} center`}>
          <div className={`${styles.navLeft}`}>Legal</div>
          <div className="center">
            <a href="/" className={`${styles.navLink}`}>
              Terms of use
            </a>
            <a href="/" className={`${styles.navLink}`}>
              Privacy policy
            </a>
          </div>
          {/* </div> */}
        </div>
      </div>
    </nav>
  );
};
export default Footer;
