import styles from "./Contacts.module.css";

const ContactsList = ({ body }) => {
  return (
    <div className={styles.contactsListContent}>
      {body.map((listItem) => {
        return (
          <a href="/" className={styles.contactsBoxLink}>
            {listItem}
          </a>
        );
      })}
    </div>
  );
};

export default ContactsList;
