import styles from "./About.module.css";
import CountUp from "react-countup";

import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";


import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-creative";

import { Autoplay, EffectCreative } from "swiper";

import about1 from "./about1.jpg";
import about2 from "./about2.jpg";
import about3 from "./about3.jpg";
import AboutBanner from "./AboutBanner";

const About = () => {
  return (
    <div className={`${styles.aboutWrapper} center`} id="about">
      <div className={`${styles.aboutWrapperInner} center`}>
        <AnimationOnScroll
          animateIn="animate__zoomIn"
          className={styles.aboutHeadingContainer}
        >
          <p className={styles.aboutHeading}>About us</p>
          <p className={styles.aboutSubHeading}>
            The world's largest solar energy system providers
          </p>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="animate__slideInLeft"
          animateOut="animate__slideOutRight"
          delay={100}
          className={styles.aboutMain}
        >
          <div className={styles.aboutMainImgCont}>
            <Swiper
              grabCursor={true}
              effect={"creative"}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              creativeEffect={{
                prev: {
                  shadow: true,
                  origin: "left center",
                  translate: ["-5%", 0, -200],
                  rotate: [0, 100, 0],
                },
                next: {
                  origin: "right center",
                  translate: ["5%", 0, -200],
                  rotate: [0, -100, 0],
                },
              }}
              modules={[Autoplay, EffectCreative]}
              className="mySwiper6"
            >
              <SwiperSlide>
                <img src={about1} className={styles.aboutMainImg} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={about2} className={styles.aboutMainImg} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={about3} className={styles.aboutMainImg} />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={styles.aboutMainDes}>
            <h2>25+ Years Experience In Solar & Renewable Energy Industry</h2>
            <p>
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
              diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet
              lorem sit clita duo justo erat amet
            </p>
            <ul>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="#46a646"
                  class="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                Diam dolor diam ipsum
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="#46a646"
                  class="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                Aliqu diam amet diam et eos
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#46a646"
                  class="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                Tempor erat elitr rebum at clita
              </li>
            </ul>
            <button className={styles.shopBtn}>Get a quote</button>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="animate__slideInLeft"
          animateOut="animate__slideOutRight"
        >
          {/* need your help with this when I call it the page goes blank */}
          <AboutBanner />

          <div className={`${styles.aboutList} center`}>
            <div className={styles.aboutDiv}>
              <div className={styles.aboutField}>
                <CountUp end={5000000} enableScrollSpy={true} duration={2} />{" "}
                <span>+</span>
                <p>Deployed panels</p>
              </div>
              <div className={styles.aboutDes}>Across the country</div>
            </div>
            <div className={styles.aboutDiv}>
              <div className={styles.aboutField}>
                <CountUp end={36} enableScrollSpy={true} duration={2} />{" "}
                <p>Branches</p>
              </div>
              <div className={styles.aboutDes}>Atleast one every state</div>
            </div>
            <div className={styles.aboutDiv}>
              <div className={styles.aboutField}>
                <CountUp end={2500000} enableScrollSpy={true} duration={2} />{" "}
                <span>+</span>
                <p>Satisfied clients</p>
              </div>
              <div className={styles.aboutDes}>Across the country</div>
            </div>
            <div className={styles.aboutDiv}>
              <div className={styles.aboutField}>
                <CountUp end={1000} enableScrollSpy={true} duration={2} />{" "}
                <span>+</span>
                <p>Order</p>
              </div>
              <div className={styles.aboutDes}>recieved yearly</div>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default About;
