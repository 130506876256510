export const contacts_list = {
  logo: "logo-sm.png",
  arr: [
    {
      id: 1,
      title: "Social media",
      body: ["Facebook", "Instagram", "Twitter", "Linkedin"],
    },
    {
      id: 2,
      title: "Get support 24/7",
      body: [
        "E-mail: smth@support.com",
        "Phone: +00 00000000",
        "GSM: 11223344556677",
      ],
    },
    {
      id: 3,
      title: "Get our app",
      body: ["IOS", "Android"],
    },
  ],
};
