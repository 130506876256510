export const services = [
  {
    service: "Mounting",
    description: "Mounting Solar Panels",
    image: "https://themewagon.github.io/solartec/img/img-600x400-5.jpg",
  },
  {
    service: "Maintenance",
    description: "Maintain Solar Panels",
    image:
      "https://solarpowerdirect.com.au/images/news/_large/solar-panel-system-maintenance-important-blog.jpg",
  },
  {
    service: "Cleaning",
    description: "Cleaning Solar Panels",
    image:
      "https://www.energycon.solar/wp-content/uploads/2022/01/workman-cleaning-solar-panels-2021-09-01-15-21-01-utc-scaled.jpg",
  },
];
