import styles from "./About.module.css";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";

export default function AboutBanner() {
  return (
    <ParallaxProvider>
      <ParallaxBanner
        layers={[
          {
            image: "https://www.sudhirpower.com/images/solar-system.jpg",
            speed: -15,
          },
        ]}
        className={styles.aspect}
      />
    </ParallaxProvider>
  );
}
