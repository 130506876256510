export const questions = [
  {
    question: "What are the financial benefits of solar energy?",
    answer:
      "When you install a solar energy system on your property, you save money on your electricity bills and protect yourself against rising electricity rates in the future\n" +
      "\n" +
      "How much you can save depends on the utility rates and solar policies in your area, but going solar is a smart investment regardless of where you live .",
  },
  {
    question: "What are the environmental benefits of solar energy?",
    answer:
      "Solar power, like other renewable energy resources, has many environmental and health benefits. Going solar reduces greenhouse gas emissions, \n" +
      "\n" +
      "which contribute to climate change, and also results in fewer air pollutants like sulfur dioxide and particulate matter, which can cause health problems.\n",
  },
  {
    question: "How much will solar panel maintenance cost?",
    answer:
      "Solar panel systems are made of durable tempered glass and require little to no maintenance for the 25 to 35 years that they will generate power. In most cases, you don’t even need to clean your solar panels regularly.\n" +
      "\n" +
      " If something does happen, most equipment manufacturers include warranties, although warranty terms depend on the company.\n",
  },
  {
    question: "Can I afford to go solar?",
    answer:
      "If you can afford to pay your electricity bill you can afford to go solar. $0-down solar financing options, \n" +
      "\n" +
      "including both solar loans and solar leases, make it easy for homeowners with good credit to start saving on their electricity bills by going solar. \n" +
      "\n" +
      "Register on the EnergySage Solar Marketplace to compare costs and savings for multiple financing options.\n",
  },
];
