import { Fragment } from "react";
import styles from "./Services.module.css";
import { services } from "../../constants/services";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Services = () => {
  return (
    <div className={`${styles.servicesWrapper} center`} id="services">
      <AnimationOnScroll
        animateIn="animate__zoomIn"
        className={styles.servicesHeading}
      >
        <p>Our Services</p>
      </AnimationOnScroll>
      <div className={`${styles.servicesListWrapper} center`}>
        <AnimationOnScroll
          animateIn="animate__slideInLeft"
          animateOut="animate__slideOutRight"
          delay={100}
          className={`${styles.servicesList} center`}
        >
          {services.map(({ service, description, image }) => {
            return (
              <div className={`${styles.serviceDiv} center`}>
                <Fragment className={styles.container}>
                  <div className={styles.service}>
                    <p>{service}</p>
                  </div>
                  <div className={styles.serviceDescription}>
                    <p>{description}</p>
                  </div>
                  <div>
                    <img className={styles.serviceImg} src={image} alt="img" />
                  </div>
                </Fragment>
              </div>
            );
          })}
        </AnimationOnScroll>
      </div>
      <button className={styles.shopBtn}>Get a quote</button>
    </div>
  );
};

export default Services;
