import React from "react";
import styles from "./Contacts.module.css";
import ContactsList from "./ContactsList";

const ContactsRight = ({ contacts }) => {
  return (
    <div className={`${styles.contactsRight} center`}>
      {contacts.arr.map(({ title, body }) => {
        return (
          <div className={`${styles.contactsBox} center`}>
            <p className={styles.contactsBoxTitle}>{title}</p>
            <ContactsList body={body} />
          </div>
        );
      })}
    </div>
  );
};

export default ContactsRight;
