import styles from "./Contacts.module.css";
import ContactsRight from "./ContactsRight";
import ContactsRightResponsive from "./ContactsRightResponsive";

import { TypeAnimation } from "react-type-animation";

const Contacts = ({ contacts }) => {
  return (
    <div className={`${styles.contactsWrapper} center`} id="contacts">
      <div className={`${styles.contactsWrapperInner} center`}>
        <div className={`${styles.contactsLeft} center`}>
          <div className={`${styles.contactsLeftInner} center`}>
            <div>
              <img src={contacts.logo} alt="logo" />
            </div>
            <div className={styles.slogan}>
              Get {""}
              <TypeAnimation
                sequence={["clean", 2000, "safe", 2000, "reliable", 2000]}
                style={{ fontSize: "1em" }}
                wrapper="span"
                repeat={Infinity}
              />
              {""}energy with FMF
            </div>
          </div>
        </div>
        <ContactsRight contacts={contacts} />

        <div className={styles.contactRightRes}>
          <ContactsRightResponsive contacts={contacts} />
        </div>
      </div>
    </div>
  );
};

export default Contacts;
