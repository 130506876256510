import React from "react";
import Accordion from "../utils/Accordion";
import styles from "./Contacts.module.css";

const ContactsRightResponsive = ({ contacts }) => {
  return (
    <>
      {contacts.arr.map(({ id, title, body }) => {
        return (
          <div className={` ${styles.contactRightResInner}`} key={id}>
            <Accordion
              isUpper={false}
              title={title}
              content={body}
              iconOpen="angle-down"
              iconClose="angle-up"
            ></Accordion>
          </div>
        );
      })}
    </>
  );
};

export default ContactsRightResponsive;
