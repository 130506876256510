import React from "react";
import styles from "./Navbar.module.css";
import AnchorLink from "react-anchor-link-smooth-scroll-v2";

const Navbar = () => {
  const logo = "./logo.png";

  // // for some unknown reason doesn't work anymore

  // const handleScrollHome = () => {
  //   document.getElementById("hero").scrollIntoView({ behavior: "smooth" });
  //   }

  // const handleScrollَAbout = () => {
  //   const element = document.getElementById("about");
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  // const handleScrollServices = () => {
  //   const element = document.getElementById("services");
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  // const handleScrollContacts = () => {
  //   const element = document.getElementById("contacts");
  //   if (element) 
  // };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="logo" className={styles.brand} />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <AnchorLink
                href="#hero"
                className="nav-link pointer"
                role="button"
              >
                Home
              </AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink
                href="#about"
                className="nav-link pointer"
                role="button"
              >
                About
              </AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink
                href="#services"
                className="nav-link pointer"
                role="button"
              >
                Services
              </AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink
                href="#contacts"
                className="nav-link pointer"
                role="button"
              >
                Contacts
              </AnchorLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
