import React from "react";
import "./index.css"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { ScrollerMotion } from "scroller-motion";

import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import Services from "./components/Services/Services";
import Questions from "./components/Questions/Questions";
import Contacts from "./components/Contacts/Contacts";
import Footer from "./components/Footer/Footer";

import { contacts_list } from "./constants/contacts_list";
import About from "./components/About/About";
import { ScrollToTop } from "react-simple-scroll-up";

const App = () => {

  return (
    <div className="App">
      <ScrollerMotion>
        <Navbar />
        <Hero />
        <About />
        <Services />
        <Questions />
        <AnimationOnScroll
          animateIn="animate__slideInLeft"
          animateOut="animate__slideOutRight"
        >
          <Contacts contacts={contacts_list} />
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="animate__slideInLeft"
          animateOut="animate__slideOutRight"
        >
          <Footer />
        </AnimationOnScroll>
      </ScrollerMotion>
      <ScrollToTop bgColor={"#0aad0a"} />
    </div>
  );
};

export default App;
