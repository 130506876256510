import styles from "./Hero.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import { EffectCube, Autoplay, Pagination } from "swiper";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Hero = () => {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      loop={true}
      modules={[EffectCube, Autoplay, Pagination]}
      cubeEffect={{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      }}
      effect={"cube"}
      grabCursor={true}
      autoplay={{
        delay: 10000,
        pauseOnMouseEnter: true,
      }}
      pagination={{
        type: "progressbar",
      }}
    >
      <SwiperSlide>
        <div className={`${styles.heroWrapper1} center`} id="hero">
          <div className={`${styles.heroInner}`}>
            <AnimationOnScroll
              animateIn="animate__slideInLeft"
            >
              <h2 className={styles.headerText}>
                Pioneers of solar and renewable energy
              </h2>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slideInLeft"
              delay={100}
              className={styles.slogan}
            >
              <p>The future is in renewable</p>
            </AnimationOnScroll>
            <div className={styles.btnGroup}>
              <button className={styles.btn} role="button">
                Get a quote
              </button>
              <button className={styles.btn} role="button">
                Contact us
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={`${styles.heroWrapper2} center`} id="hero">
          <div className={`${styles.heroInner}`}>
            <AnimationOnScroll
              animateIn="animate__slideInLeft"
            >
              <h2 className={styles.headerText}>
                Pioneers of solar and renewable energy
              </h2>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slideInLeft"
              delay={100}
              className={styles.slogan}
            >
              <p>The future is in renewable</p>
            </AnimationOnScroll>
            <div className={styles.btnGroup}>
              <button className={styles.btn} role="button">
                Get a quote
              </button>
              <button className={styles.btn} role="button">
                Contact us
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={`${styles.heroWrapper3} center`} id="hero">
          <div className={`${styles.heroInner}`}>
            <AnimationOnScroll
              animateIn="animate__slideInLeft"
            >
              <h2 className={styles.headerText}>
                Pioneers of solar and renewable energy
              </h2>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__slideInLeft"
              delay={100}
              className={styles.slogan}
            >
              <p>The future is in renewable</p>
            </AnimationOnScroll>
            <div className={styles.btnGroup}>
              <button className={styles.btn} role="button">
                Get a quote
              </button>
              <button className={styles.btn} role="button">
                Contact us
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
export default Hero;
